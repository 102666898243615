import React from "react";

import Firebase from "firebase";
import config from "./config.js";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Scoreboard2x2 from "./2x2/scoreboard2x2"
import Keypad2x2 from "./2x2/keypad2x2"
import ScoreboardKing from "./king/scoreboardKing"
import KeypadKing from "./king/keypadKing"
import SettingsKing from "./king/settingsKing"
import Menu from "./menu"
import "./App.scss";

class App extends React.Component {

  constructor(props) {
    super(props);

    if (Firebase.apps.length === 0) {
      Firebase.initializeApp(config);
    }

    this.state = {
      game: [],
      scores: [],
      groups: [],
      show: 'scores',
      teamName1: 'A',
      teamName2: 'B',
      kingScore1: 0,
      kingScore2: 0,
      kingScore3: 0,
      kingScore4: 0,
      kingScore5: 0,
      kingScore6: 0,
      kingTeamName1:'A',
      kingTeamName2:'B',
      kingTeamName3:'C',
      kingTeamName4:'D',
      kingTeamName5:'E',
      kingTeamName6:'F',
      kingTeamCount: 6,
      kingKing: 'TEAM-1',
      kingChallenger: 'TEAM-2',
      kingNext1: 'TEAM-3',
      kingNext2: 'TEAM-4',
      kingNext3: 'TEAM-5',
      kingNext4: 'TEAM-6',
      kingEndOfTime: 0,
      kingMinutesInGame: 15,
      kingLastStatus: {},
      kingHideScoreboard: 0
    };
  }

  componentDidMount() {
    this.getScoreData();
    this.getDisplayData();
    this.getKingData();
    //this.getAllData();
  }



  getDisplayData = () => {
    let ref = Firebase.database().ref("/display/");
    ref.on("value", (snapshot) => {
      const show = snapshot.val();
      this.setState(show);
    });
  };

  getKingData = () => {
    let ref = Firebase.database().ref("/king/");
    ref.on("value", (snapshot) => {
      const king = snapshot.val();
      this.setState(king);
    });
  };


  getScoreData = () => {
    let ref = Firebase.database().ref("/game/2/");
    ref.on("value", (snapshot) => {
      const state = snapshot.val();
      this.setState(state);
    });
  };

  getAllData = () => {
    let ref = Firebase.database().ref("/");
    ref.on("value", (snapshot) => {
      const allData = snapshot.val();
      this.setState(allData);
      console.log(allData);
    });
  };


  render() {

    return (
      <Router path="/" component={Main} >


      <Switch>

        <Route path="/ipad" >
          <Scoreboard2x2 show={this.state.show}
                         score1={this.state.score1}
                         score2={this.state.score2}
                         teamName1={this.state.teamName1}
                         teamName2={this.state.teamName2}
                         inning1={this.state.inning1}
                         inning2={this.state.inning2}
          />
            <Keypad2x2   score1={this.state.score1}
                         score2={this.state.score2}
                         teamName1={this.state.teamName1}
                         teamName2={this.state.teamName2}
                         inning1={this.state.inning1}
                         inning2={this.state.inning2}    />


              <Menu  teamName1={this.state.teamName1}
                     teamName2={this.state.teamName2} />


        </Route>



        <Route exact path="/tv">
          <Scoreboard2x2 show={this.state.show}
                         score1={this.state.score1}
                         score2={this.state.score2}
                         teamName1={this.state.teamName1}
                         teamName2={this.state.teamName2}
                         inning1={this.state.inning1}
                         inning2={this.state.inning2}
          />
        </Route>


        <Route exact path="/king">
          <ScoreboardKing score1={this.state.kingScore1}
                          score2={this.state.kingScore2}
                          score3={this.state.kingScore3}
                          score4={this.state.kingScore4}
                          score5={this.state.kingScore5}
                          score6={this.state.kingScore6}
                          teamName1={this.state.kingTeamName1}
                          teamName2={this.state.kingTeamName2}
                          teamName3={this.state.kingTeamName3}
                          teamName4={this.state.kingTeamName4}
                          teamName5={this.state.kingTeamName5}
                          teamName6={this.state.kingTeamName6}
                          teamCount={this.state.kingTeamCount}
                          king={this.state.kingKing}
                          challenger={this.state.kingChallenger}
                          next1={this.state.kingNext1}
                          next2={this.state.kingNext2}
                          next3={this.state.kingNext3}
                          next4={this.state.kingNext4}
                          endOfTime={this.state.kingEndOfTime}
                          minutesInGame={this.state.kingMinutesInGame}
                          hideScoreboard={this.state.kingHideScoreboard}

          />
        </Route>

        <Route path="/king/ipad" >
          <ScoreboardKing score1={this.state.kingScore1}
                         score2={this.state.kingScore2}
                          score3={this.state.kingScore3}
                          score4={this.state.kingScore4}
                          score5={this.state.kingScore5}
                          score6={this.state.kingScore6}
                          teamName1={this.state.kingTeamName1}
                          teamName2={this.state.kingTeamName2}
                          teamName3={this.state.kingTeamName3}
                          teamName4={this.state.kingTeamName4}
                          teamName5={this.state.kingTeamName5}
                          teamName6={this.state.kingTeamName6}
                          teamCount={this.state.kingTeamCount}
                          king={this.state.kingKing}
                          challenger={this.state.kingChallenger}
                          next1={this.state.kingNext1}
                          next2={this.state.kingNext2}
                          next3={this.state.kingNext3}
                          next4={this.state.kingNext4}
                          endOfTime={this.state.kingEndOfTime}
                          minutesInGame={this.state.kingMinutesInGame}
                          hideScoreboard={this.state.kingHideScoreboard}

          />
          <KeypadKing score1={this.state.kingScore1}
                      score2={this.state.kingScore2}
                      score3={this.state.kingScore3}
                      score4={this.state.kingScore4}
                      score5={this.state.kingScore5}
                      score6={this.state.kingScore6}
                      teamName1={this.state.kingTeamName1}
                      teamName2={this.state.kingTeamName2}
                      teamName3={this.state.kingTeamName3}
                      teamName4={this.state.kingTeamName4}
                      teamName5={this.state.kingTeamName5}
                      teamName6={this.state.kingTeamName6}
                      teamCount={this.state.kingTeamCount}
                      endOfTime={this.state.kingEndOfTime}
                      minutesInGame={this.state.minutesInGame}
                      king={this.state.kingKing}
                      challenger={this.state.kingChallenger}
                      next1={this.state.kingNext1}
                      next2={this.state.kingNext2}
                      next3={this.state.kingNext3}
                      next4={this.state.kingNext4}
                      minutesInGame={this.state.kingMinutesInGame}
                      lastStatus={this.state.kingLastStatus}
                      hideScoreboard={this.state.kingHideScoreboard}
                       />


          <SettingsKing  score1={this.state.kingScore1}
                         score2={this.state.kingScore2}
                         score3={this.state.kingScore3}
                         score4={this.state.kingScore4}
                         score5={this.state.kingScore5}
                         score6={this.state.kingScore6}
                         teamName1={this.state.kingTeamName1}
                         teamName2={this.state.kingTeamName2}
                         teamName3={this.state.kingTeamName3}
                         teamName4={this.state.kingTeamName4}
                         teamName5={this.state.kingTeamName5}
                         teamName6={this.state.kingTeamName6}
                         teamCount={this.state.kingTeamCount}
                         minutesInGame={this.state.minutesInGame}

                          />

        </Route>

      </Switch>

      </Router>
    );
  }
}

export default App;


function Main() {
}

