import React from 'react';
import Firebase from "firebase";
import config from "./config.js";


class Menu extends React.Component {

    constructor(props) {
        super(props);
        if (Firebase.apps.length === 0) {
            Firebase.initializeApp(config);
        }
        this.state = {
            team1: props.teamName1,
            team2: props.teamName2,
        }

    }

    componentDidMount() {
    }

    updateViewer(view){
        Firebase.database()
            .ref("display/")
            .set({
                show: view
            });
    }

    updateTeamNames(gameId) {
        const updates = {};
        updates[`game/${gameId}/teamName1`] = this.state.team1;
        updates[`game/${gameId}/teamName2`] = this.state.team2;
        Firebase.database().ref().update(updates);
    }

    render() {

        return (
            <React.Fragment>
                <div className="menu">
                    <div className="brand">
                        <img src="/logo.svg" alt="Sandkastalinn"/>
                    </div>
                    <ul>
                        <li className="icon icon-field" onClick={() => this.updateViewer('scores')}>Stigatafla</li>
                        <li className="icon icon-tv" onClick={() => this.updateViewer('tv')}>Sjónvarp</li>
                    </ul>
                    <div className="input-fields">
                        <h2>Nöfn á liðum</h2>
                        <div className="input-group">
                            <input type="text"
                                   data-id="team2"
                                   name="team2"
                                   value={this.state.team2}
                                   onChange={e => {
                                       this.setState({ team2: e.target.value });
                                       this.value = this.state.team2;
                                   }}
                            />
                            <label htmlFor="team2">
                                Lið #1:
                            </label>
                            <div className="req-mark">!</div>
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <input type="text"
                                   data-id="team1"
                                   value={this.state.team1}
                                   onChange={e => {
                                        this.setState({ team1: e.target.value });
                                        this.value = this.state.team1;
                                    }}
                            />
                            <label>
                                Lið #2:
                            </label>
                            <div className="req-mark">!</div>
                        </div>
                        <button
                            onClick={() => this.updateTeamNames(2)}
                            className="btn btn-link"
                        >
                            Uppfæra
                        </button>

                    </div>
                </div>
            </React.Fragment>
        );

    }
}


export default Menu;
