import React from 'react';




class ScoreboardKing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startedAnimation: false,
            isStopped: false,
            isPaused: false,
            team1fontsize: 38,
            team2fontsize: 38,
            timeLeft: '',
            endOfTime: props.endOfTime,
            minutesInGame: props.minutesInGame,
            king: props.king,
            challenger: props.challenger,
            next1: props.next1,
            next2: props.next2,
            next3: props.next3,
            team1: props.teamName1,
            team2: props.teamName2,
            team3: props.teamName3,
            team4: props.teamName4,
            team5: props.teamName5,
            team6: props.teamName6,
            score1: props.score1,
            score2: props.score2,
            score3: props.score3,
            score4: props.score4,
            score5: props.score5,
            score6: props.score6,
            teamCount: props.teamCount,
            highScoreName1: '',
            highScoreName2: '',
            highScoreName3: '',
            highScoreName4: '',
            highScoreName5: '',
            highScoreName6: '',
            highScore1: 0,
            highScore2: 0,
            highScore3: 0,
            highScore4: 0,
            highScore5: 0,
            highScore6: 0,
            scoreIsDirty: true,
            hideScoreboard: 0
        };
    }

    componentDidMount() {
        this.intervalId = setInterval(this.checkClock.bind(this), 1000);
    }
    componentWillUnmount(){
        clearInterval(this.intervalId);
    }


    componentDidUpdate(prevProps) {
        let scoreIsDirty = false;
        // Typical usage (don't forget to compare props):
        if (this.props.teamName1 !== prevProps.teamName1) {
            this.setState({team1: this.props.teamName1});
        }
        if (this.props.teamName2 !== prevProps.teamName2) {
            this.setState({team2: this.props.teamName2});
        }
        if (this.props.teamName3 !== prevProps.teamName3) {
            this.setState({team3: this.props.teamName3});
        }
        if (this.props.teamName4 !== prevProps.teamName4) {
            this.setState({team4: this.props.teamName4});
        }
        if (this.props.teamName5 !== prevProps.teamName5) {
            this.setState({team5: this.props.teamName5});
        }
        if (this.props.teamName6 !== prevProps.teamName6) {
            this.setState({team6: this.props.teamName6});
        }
        if (this.props.score1 !== prevProps.score1) {
            this.setState({score1: this.props.score1});
            scoreIsDirty = true;
        }
        if (this.props.score2 !== prevProps.score2) {
            this.setState({score2: this.props.score2});
            scoreIsDirty = true;
        }
        if (this.props.score3 !== prevProps.score3) {
            this.setState({score3: this.props.score3});
            scoreIsDirty = true;
        }
        if (this.props.score4 !== prevProps.score4) {
            this.setState({score4: this.props.score4});
            scoreIsDirty = true;
        }
        if (this.props.score5 !== prevProps.score5) {
            this.setState({score5: this.props.score5});
            scoreIsDirty = true;
        }
        if (this.props.score6 !== prevProps.score6) {
            this.setState({score6: this.props.score6});
            scoreIsDirty = true;
        }
        if (this.props.teamCount !== prevProps.teamCount) {
            this.setState({teamCount: this.props.teamCount});
        }
        if (this.props.endOfTime !== prevProps.endOfTime) {
            this.setState({endOfTime: this.props.endOfTime});
        }
        if (this.props.king !== prevProps.king) {
            this.setState({king: this.props.king});
        }
        if (this.props.challenger !== prevProps.challenger) {
            this.setState({challenger: this.props.challenger});
        }
        if (this.props.next1 !== prevProps.next1) {
            this.setState({next1: this.props.next1});
        }
        if (this.props.next2 !== prevProps.next2) {
            this.setState({next2: this.props.next2});
        }
        if (this.props.next3 !== prevProps.next3) {
            this.setState({next3: this.props.next3});
        }
        if (this.props.next4 !== prevProps.next4) {
            this.setState({next4: this.props.next4});
        }
        if (this.props.minutesInGame !== prevProps.minutesInGame) {
            this.setState({minutesInGame: this.props.minutesInGame});
        }
        if (this.props.hideScoreboard !== prevProps.hideScoreboard) {
            this.setState({hideScoreboard: this.props.hideScoreboard});
        }
        if(scoreIsDirty) {
            this.setState({scoreIsDirty: true});
        }
    }


    calculateHighScore() {

        const teamCount = this.state.teamCount;

        let teamListArray = [{name: this.state.team1, score: this.state.score1}, {name: this.state.team2, score: this.state.score2}, {name: this.state.team3, score: this.state.score3}];

        if(teamCount > 3) {
            teamListArray.push({name: this.state.team4, score: this.state.score4});
        };
        if(teamCount > 4) {
            teamListArray.push({name: this.state.team5, score: this.state.score5});
        };
        if(teamCount > 5) {
            teamListArray.push({name: this.state.team6, score: this.state.score6});
        };


        let sortable = teamListArray.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))


        this.setState ({
            highScoreName1:  sortable[0].name,
            highScoreName2:  sortable[1].name,
            highScoreName3:  sortable[2].name,
            highScoreName4:  (teamCount > 3 ? sortable[3].name : ''),
            highScoreName5:  (teamCount > 4 ? sortable[4].name : ''),
            highScoreName6:  (teamCount > 5 ? sortable[5].name : ''),
            highScore1:  sortable[0].score,
            highScore2:  sortable[1].score,
            highScore3:  sortable[2].score,
            highScore4:  (teamCount > 3 ? sortable[3].score : ''),
            highScore5:  (teamCount > 4 ? sortable[4].score : ''),
            highScore6:  (teamCount > 5 ? sortable[5].score : ''),
            scoreIsDirty: false});
    }


    checkClock() {
        if (this.state.endOfTime) {

            const currentTime = new Date().getTime();
            const endTime = this.state.endOfTime;
            const diffTime = endTime - currentTime;
            let minutes = Math.floor(diffTime / 60000);
            let seconds = ((diffTime % 60000) / 1000).toFixed(0);
            if (seconds == 60) {
                seconds = 0;
                minutes += 1;
            }
            let timeLeftString = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;

            if (diffTime >= 0) {
                this.setState({timeLeft: timeLeftString, startedAnimation: false});
            } else if (diffTime < 0 && diffTime > -15000) {
                this.setState({timeLeft: null, startedAnimation: true});
            }
                else
            {
                this.setState({timeLeft: null, startedAnimation: false});
            }
        }
    }


    render() {
        if(this.state.scoreIsDirty) {
            this.calculateHighScore();
        }


        return (
            <React.Fragment>
                <div className="main-board kings-game">
                    {this.state.hideScoreboard === 1 &&
                        <div className="tv-display scorecard kingDisplay" id="scoreboard1">
                            <img src="../hidescoreboard.png" alt=""/>
                        </div>
                    }
                    {this.state.hideScoreboard !== 1 &&
                        <div className="tv-display scorecard kingDisplay" id="scoreboard1">
                        <div className="clockHolder">
                            {this.state.timeLeft && !this.state.startedAnimation ?
                                <div className="clock">
                                    <div>{this.state.timeLeft}</div>
                                </div>
                                : null
                            }
                            <div className="logo rig"></div>
                        </div>


                        <div className="nextup">
                            <h3>Næst inn</h3>
                            <div className="runnerup runner1">
                                <ol>
                                    {this.state.next1 === 'TEAM-1' &&
                                    <li>{this.state.team1} <span>{this.state.score1}</span></li>
                                    }
                                    {this.state.next1 === 'TEAM-2' &&
                                    <li>{this.state.team2} <span>{this.state.score2}</span></li>
                                    }
                                    {this.state.next1 === 'TEAM-3' &&
                                    <li>{this.state.team3} <span>{this.state.score3}</span></li>
                                    }
                                    {this.state.next1 === 'TEAM-4' &&
                                    <li>{this.state.team4} <span>{this.state.score4}</span></li>
                                    }
                                    {this.state.next1 === 'TEAM-5' &&
                                    <li>{this.state.team5} <span>{this.state.score5}</span></li>
                                    }
                                    {this.state.next1 === 'TEAM-6' &&
                                    <li>{this.state.team6} <span>{this.state.score6}</span></li>
                                    }

                                    {this.state.next2 === 'TEAM-1' &&
                                    <li>{this.state.team1} <span>{this.state.score1}</span></li>
                                    }
                                    {this.state.next2 === 'TEAM-2' &&
                                    <li>{this.state.team2} <span>{this.state.score2}</span></li>
                                    }
                                    {this.state.next2 === 'TEAM-3' &&
                                    <li>{this.state.team3} <span>{this.state.score3}</span></li>
                                    }
                                    {this.state.next2 === 'TEAM-4' &&
                                    <li>{this.state.team4} <span>{this.state.score4}</span></li>
                                    }
                                    {this.state.next2 === 'TEAM-5' &&
                                    <li>{this.state.team5} <span>{this.state.score5}</span></li>
                                    }
                                    {this.state.next2 === 'TEAM-6' &&
                                    <li>{this.state.team6} <span>{this.state.score6}</span></li>
                                    }

                                    {this.state.next3 === 'TEAM-1' &&
                                    <li>{this.state.team1} <span>{this.state.score1}</span></li>
                                    }
                                    {this.state.next3 === 'TEAM-2' &&
                                    <li>{this.state.team2} <span>{this.state.score2}</span></li>
                                    }
                                    {this.state.next3 === 'TEAM-3' &&
                                    <li>{this.state.team3} <span>{this.state.score3}</span></li>
                                    }
                                    {this.state.next3 === 'TEAM-4' &&
                                    <li>{this.state.team4} <span>{this.state.score4}</span></li>
                                    }
                                    {this.state.next3 === 'TEAM-5' &&
                                    <li>{this.state.team5} <span>{this.state.score5}</span></li>
                                    }
                                    {this.state.next3 === 'TEAM-6' &&
                                    <li>{this.state.team6} <span>{this.state.score6}</span></li>
                                    }

                                    {this.state.next4 === 'TEAM-1' &&
                                    <li>{this.state.team1} <span>{this.state.score1}</span></li>
                                    }
                                    {this.state.next4 === 'TEAM-2' &&
                                    <li>{this.state.team2} <span>{this.state.score2}</span></li>
                                    }
                                    {this.state.next4 === 'TEAM-3' &&
                                    <li>{this.state.team3} <span>{this.state.score3}</span></li>
                                    }
                                    {this.state.next4 === 'TEAM-4' &&
                                    <li>{this.state.team4} <span>{this.state.score4}</span></li>
                                    }
                                    {this.state.next4 === 'TEAM-5' &&
                                    <li>{this.state.team5} <span>{this.state.score5}</span></li>
                                    }
                                    {this.state.next4 === 'TEAM-6' &&
                                    <li>{this.state.team6} <span>{this.state.score6}</span></li>
                                    }

                                </ol>
                            </div>
                        </div>
                        {!this.state.timeLeft ?
                            <div className="highScoreTable">
                                <h2>Stig</h2>
                                <ol>
                                    <li>{this.state.highScoreName1} <span>{this.state.highScore1}</span></li>
                                    <li>{this.state.highScoreName2} <span>{this.state.highScore2}</span></li>
                                    <li>{this.state.highScoreName3} <span>{this.state.highScore3}</span></li>
                                    {this.state.highScoreName4 &&
                                    <li>{this.state.highScoreName4} <span>{this.state.highScore4}</span></li>
                                    }
                                    {this.state.highScoreName5 &&
                                    <li>{this.state.highScoreName5} <span>{this.state.highScore5}</span></li>
                                    }
                                    {this.state.highScoreName6 &&
                                    <li>{this.state.highScoreName6} <span>{this.state.highScore6}</span></li>
                                    }
                                </ol>
                                <div className="logo rig"></div>
                            </div>
                            : null}


                        {!this.state.timeLeft ?
                            <div className="gameOver">
                                <h2 className="title">King of the Court</h2>
                                <div className="winner">
                                    <h1>{this.state.highScoreName1}</h1>
                                </div>
                            </div>
                            : null
                        }

                        <div className="flex-rows">
                            <div className="flex-row riggreen">
                            </div>
                            <div className="flex-row">
                                <div className="scoreboard">
                                    <div className="courtside kingside">
                                        <h4>Konungsvöllur</h4>
                                    </div>
                                    <div className="courtside challengers">
                                        <h4>Áskorendur</h4>
                                    </div>


                                    <div className="kingScore score1">
                                        {this.state.king === 'TEAM-1' &&
                                        <h4>{this.state.score1} </h4>
                                        }
                                        {this.state.king === 'TEAM-2' &&
                                        <h4>{this.state.score2} </h4>
                                        }
                                        {this.state.king === 'TEAM-3' &&
                                        <h4>{this.state.score3} </h4>
                                        }
                                        {this.state.king === 'TEAM-4' &&
                                        <h4>{this.state.score4} </h4>
                                        }
                                        {this.state.king === 'TEAM-5' &&
                                        <h4>{this.state.score5} </h4>
                                        }
                                        {this.state.king === 'TEAM-6' &&
                                        <h4>{this.state.score6} </h4>
                                        }
                                    </div>
                                    <div className="kingScore score2">
                                        {this.state.challenger === 'TEAM-1' &&
                                        <h4>{this.state.score1} </h4>
                                        }
                                        {this.state.challenger === 'TEAM-2' &&
                                        <h4>{this.state.score2} </h4>
                                        }
                                        {this.state.challenger === 'TEAM-3' &&
                                        <h4>{this.state.score3} </h4>
                                        }
                                        {this.state.challenger === 'TEAM-4' &&
                                        <h4>{this.state.score4} </h4>
                                        }
                                        {this.state.challenger === 'TEAM-5' &&
                                        <h4>{this.state.score5} </h4>
                                        }
                                        {this.state.challenger === 'TEAM-6' &&
                                        <h4>{this.state.score6} </h4>
                                        }
                                    </div>

                                    <div className="kingTeam king">
                                        {this.state.king === 'TEAM-1' &&
                                        <h4>{this.state.team1}</h4>
                                        }
                                        {this.state.king === 'TEAM-2' &&
                                        <h4>{this.state.team2}</h4>
                                        }
                                        {this.state.king === 'TEAM-3' &&
                                        <h4>{this.state.team3}</h4>
                                        }
                                        {this.state.king === 'TEAM-4' &&
                                        <h4>{this.state.team4}</h4>
                                        }
                                        {this.state.king === 'TEAM-5' &&
                                        <h4>{this.state.team5}</h4>
                                        }
                                        {this.state.king === 'TEAM-6' &&
                                        <h4>{this.state.team6}</h4>
                                        }
                                    </div>
                                    <div className="kingTeam challenger">
                                        {this.state.challenger === 'TEAM-1' &&
                                        <h4>{this.state.team1}</h4>
                                        }
                                        {this.state.challenger === 'TEAM-2' &&
                                        <h4>{this.state.team2}</h4>
                                        }
                                        {this.state.challenger === 'TEAM-3' &&
                                        <h4>{this.state.team3}</h4>
                                        }
                                        {this.state.challenger === 'TEAM-4' &&
                                        <h4>{this.state.team4}</h4>
                                        }
                                        {this.state.challenger === 'TEAM-5' &&
                                        <h4>{this.state.team5}</h4>
                                        }
                                        {this.state.challenger === 'TEAM-6' &&
                                        <h4>{this.state.team6}</h4>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </React.Fragment>
        );

    }
}


export default ScoreboardKing;
