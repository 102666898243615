import React from 'react';
import Firebase from "firebase";
import config from "../config.js";


class Keypad2x2 extends React.Component {

    constructor(props) {
        super(props);
        if (Firebase.apps.length === 0) {
            Firebase.initializeApp(config);
        }
    }

    componentDidMount() {
    }

    writeGameData(gameId, score1, score2, inning1, inning2,teamName1, teamName2) {
        Firebase.database()
            .ref("game/" + gameId)
            .set({
                score1: score1,
                score2: score2,
                inning1: inning1,
                inning2: inning2,
                teamName1: teamName1,
                teamName2: teamName2
            });
    }

    switchGameData(gameId) {
        let score1_switched = this.props.score2;
        let score2_switched = this.props.score1;
        let inning1_switched = this.props.inning2;
        let inning2_switched = this.props.inning1;
        let teamName1_switched = this.props.teamName2
        let teamName2_switched = this.props.teamName1;
        this.writeGameData(gameId, score1_switched, score2_switched, inning1_switched, inning2_switched, teamName1_switched,  teamName2_switched);
    }



    updateScore(gameId, scoreId, score) {

        const updates = {};
         if (scoreId === "scoreLeft") {
            updates[`game/${gameId}/score1`] = Firebase.database.ServerValue.increment(score);
        } else if (scoreId === "scoreRight") {
            updates[`game/${gameId}/score2`] = Firebase.database.ServerValue.increment(score);
        } else if (scoreId === "inningLeft") {
            updates[`game/${gameId}/inning1`] = Firebase.database.ServerValue.increment(score);
        } else if (scoreId === "inningRight") {
            updates[`game/${gameId}/inning2`] = Firebase.database.ServerValue.increment(score);
        }
        Firebase.database().ref().update(updates);
    }



    render() {


        return (
            <React.Fragment>
                <div className="score-configure">
                    <div className="score-setting plus1">
                        <h4 onClick={() => this.updateScore(2, "scoreLeft", 1)}>+</h4>
                    </div>
                    <div className="score-setting plus2">
                        <h5 onClick={() => this.updateScore(2, "scoreRight", 1)}>
                            +
                        </h5>
                    </div>
                    <div className="score-setting plus1b">
                        <h4 onClick={() => this.updateScore(2, "inningLeft", 1)}>
                            +
                        </h4>
                    </div>
                    <div className="score-setting plus2b">
                        <h5 onClick={() => this.updateScore(2, "inningRight", 1)}>
                            +
                        </h5>
                    </div>
                    <div className="score-setting minus1">
                        <h4 onClick={() => this.updateScore(2, "scoreLeft", -1)}>
                            -
                        </h4>
                    </div>
                    <div className="score-setting minus2">
                        <h4 onClick={() => this.updateScore(2, "scoreRight", -1)}>-</h4>
                    </div>
                    <div className="score-setting minus1b">
                        <h4 onClick={() => this.updateScore(2, "inningLeft", -1)}>-</h4>
                    </div>
                    <div className="score-setting minus2b">
                        <h4 onClick={() => this.updateScore(2, "inningRight", -1)}>-</h4>
                    </div>
                    <div className="btn-switch" onClick={() => this.switchGameData(2)} >
                        Switch
                    </div>
                    <div className="btn-reset" onClick={() => this.writeGameData(2, 0, 0, 0, 0, this.props.teamName1, this.props.teamName2)} >
                        Reset
                    </div>

                </div>

            </React.Fragment>
        );

    }
}


export default Keypad2x2;
