import React from 'react';
import ReactPlayer from "react-player";
import Clock from "react-live-clock";
import Lottie from "react-lottie";
import * as animationData from "../img/asphaltroller";



class Scoreboard2x2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ruvStream: '',
            startedAnimation: false,
            isStopped: false,
            isPaused: false,
            tvPlayerIsLoaded: false,
            team1fontsize: 38,
            team2fontsize: 38
        };
    }

    componentDidMount() {
        this.fetchTvPlayer();
    }

    checkClock(dateE) {
        const date = new Date(dateE);
        var minutes = date.getMinutes();
        if (minutes >= 25 && minutes < 30) {
            if(this.state.startedAnimation === false) {
                this.setState({startedAnimation: true})
            }
        } else {
            this.setState({startedAnimation: false});
        }
    }


    fetchTvPlayer() {

        fetch("https://geo.spilari.ruv.is/channel/ruv")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        tvPlayerIsLoaded: true,
                        items: result.items,
                        ruvStream: result.url
                    })
                },

                (error) => {
                    this.setState({
                        tvPlayerIsLoaded: false,
                        error
                    });
                }
            )

    }


    render() {

        const totalScore = (this.props.score1 + this.props.score2);
        const switchTime =  totalScore > 0 ? (totalScore % 7 === 0) && !this.props.justSwitched : false;
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData.default,
            rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const team1fontsize = 38 - this.props.teamName1.length;
        const team2fontsize = 38 - this.props.teamName2.length;

        return (
            <React.Fragment>
                <div className="main-board">


                    { this.props.show === 'tv' && this.state.tvPlayerIsLoaded &&
                    <div className="tv-display" id="ruv" >
                        <ReactPlayer className='react-player'
                            // This is the video address passed from the superior page
                                     url={this.state.ruvStream}
                                     playing
                                     width='512px'
                                     height='512px'
                                     controls
                                     config={{
                                         file: {
                                             forceHLS: true,
                                         }
                                     }}
                        />
                    </div>
                    }

                    { (this.props.show !== 'tv' || !this.state.tvPlayerIsLoaded) &&

                    <div
                        className="tv-display scorecard"
                        id="scoreboard1"
                        style={{ display: "block" }}
                    >
                        <div className="clock">
                            <Clock format={'HH:mm:ss'} ticking={true} timezone={'Atlantic/Reykjavik'}
                                   onChange={date => this.checkClock(date)}/>
                        </div>
                        { this.state.startedAnimation
                            ? <div className="cleanAnimation">
                                <Lottie options={defaultOptions}
                                        height={180}
                                        width={350}
                                        isStopped={this.state.isStopped}
                                        isPaused={this.state.isPaused}
                                />
                                <div className="title">Tími til að slétta völlinn</div>
                            </div>
                            : null
                        }


                        <div className="flex-rows">
                            <div className="flex-row blue">
                                {!switchTime &&
                                <img src="../logo-group.png" alt="Sponsors"/>
                                }
                                {switchTime &&
                                <img src="../switch_banner.png" alt="Switch"/>
                                }
                            </div>
                            <div className="flex-row">
                                <div className="scoreboard">
                                    <div className="score score1">
                                        <h4>{this.props.score1}</h4>
                                    </div>
                                    <div className="set set1">
                                        <h4>{this.props.inning1}</h4>
                                    </div>
                                    <div className="score score2">
                                        <h4>{this.props.score2}</h4>
                                    </div>
                                    <div className="set set2">
                                        <h4>{this.props.inning2}</h4>
                                    </div>
                                    <div className="team team1">
                                        <h4 style={{fontSize : team1fontsize+'px'}}>{this.props.teamName1}</h4>
                                    </div>
                                    <div className="team team2">
                                        <h4 style={{fontSize : team2fontsize+'px'}}>{this.props.teamName2}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </React.Fragment>
        );

    }
}


export default Scoreboard2x2;
