import React from 'react';
import Firebase from "firebase";
import config from "../config.js";


class KeypadKing extends React.Component {

    constructor(props) {
        super(props);
        if (Firebase.apps.length === 0) {
            Firebase.initializeApp(config);
        }
        this.state = {
            team1: props.teamName1,
            team2: props.teamName2,
            team3: props.teamName3,
            team4: props.teamName4,
            team5: props.teamName5,
            team6: props.teamName6,
            score1: props.score1,
            score2: props.score2,
            score3: props.score3,
            score4: props.score4,
            score5: props.score5,
            score6: props.score6,
            king: props.king,
            challenger: props.challenger,
            next1: props.next1,
            next2: props.next2,
            next3: props.next3,
            next4: props.next4,
            teamCount: props.teamCount,
            endOfTime: props.endOfTime,
            minutesInGame: props.minutesInGame,
            lastStatus: props.lastStatus,
            hideScoreboard: 0,
            extraMinutes: 1

        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.teamName1 !== prevProps.teamName1) {
            this.setState({team1: this.props.teamName1});
        }
        if (this.props.teamName2 !== prevProps.teamName2) {
            this.setState({team2: this.props.teamName2});
        }
        if (this.props.teamName3 !== prevProps.teamName3) {
            this.setState({team3: this.props.teamName3});
        }
        if (this.props.teamName4 !== prevProps.teamName4) {
            this.setState({team4: this.props.teamName4});
        }
        if (this.props.teamName5 !== prevProps.teamName5) {
            this.setState({team5: this.props.teamName5});
        }
        if (this.props.teamName6 !== prevProps.teamName6) {
            this.setState({team6: this.props.teamName6});
        }
        if (this.props.score1 !== prevProps.score1) {
            this.setState({score1: this.props.score1});
        }
        if (this.props.score2 !== prevProps.score2) {
            this.setState({score2: this.props.score2});
        }
        if (this.props.score3 !== prevProps.score3) {
            this.setState({score3: this.props.score3});
        }
        if (this.props.score4 !== prevProps.score4) {
            this.setState({score4: this.props.score4});
        }
        if (this.props.score5 !== prevProps.score5) {
            this.setState({score5: this.props.score5});
        }
        if (this.props.score6 !== prevProps.score6) {
            this.setState({score6: this.props.score6});
        }
        if (this.props.king !== prevProps.king) {
            this.setState({king: this.props.king});
        }
        if (this.props.challenger !== prevProps.challenger) {
            this.setState({challenger: this.props.challenger});
        }
        if (this.props.next1 !== prevProps.next1) {
            this.setState({next1: this.props.next1});
        }
        if (this.props.next2 !== prevProps.next2) {
            this.setState({next2: this.props.next2});
        }
        if (this.props.next3 !== prevProps.next3) {
            this.setState({next3: this.props.next3});
        }
        if (this.props.next4 !== prevProps.next4) {
            this.setState({next4: this.props.next4});
        }
        if (this.props.teamCount !== prevProps.teamCount) {
            this.setState({teamCount: this.props.teamCount});
        }
        if (this.props.endOfTime !== prevProps.endOfTime) {
            this.setState({endOfTime: this.props.endOfTime});
        }
        if (this.props.minutesInGame !== prevProps.minutesInGame) {
            this.setState({minutesInGame: this.props.minutesInGame});
        }
        if (this.props.lastStatus !== prevProps.lastStatus) {
            this.setState({lastStatus: this.props.lastStatus});
        }
        if (this.props.hideScoreboard !== prevProps.hideScoreboard) {
            this.setState({hideScoreboard: this.props.hideScoreboard});
        }
    }

    saveCurrentState() {
        const updates = {};
/*
        updates[`king/kingLastStatus/teamName1`] = this.state.team1;
        updates[`king/kingLastStatus/teamName2`] = this.state.team2;
        updates[`king/kingLastStatus/teamName3`] = this.state.team3;
        updates[`king/kingLastStatus/teamName4`] = this.state.team4;
        updates[`king/kingLastStatus/teamName5`] = this.state.team5;
        updates[`king/kingLastStatus/teamName6`] =  this.state.team6;

 */
        updates[`king/kingLastStatus/score1`] = this.state.score1;
        updates[`king/kingLastStatus/score2`] = this.state.score2;
        updates[`king/kingLastStatus/score3`] = this.state.score3;
        updates[`king/kingLastStatus/score4`] = this.state.score4;
        updates[`king/kingLastStatus/score5`] = this.state.score5;
        updates[`king/kingLastStatus/score6`] = this.state.score6;
        updates[`king/kingLastStatus/king`] = this.state.king;
        updates[`king/kingLastStatus/challenger`] =  this.state.challenger;
        updates[`king/kingLastStatus/next1`] =  this.state.next1;
        updates[`king/kingLastStatus/next2`] =  this.state.next2;
        updates[`king/kingLastStatus/next3`] =  this.state.next3;
        updates[`king/kingLastStatus/next4`] =  this.state.next4;
        Firebase.database().ref().update(updates);
    }

    undoLastScore() {
        const updates = {};
        updates[`king/kingKing`] = this.state.lastStatus.king;
        updates[`king/kingChallenger`] = this.state.lastStatus.challenger;
        updates[`king/kingNext1`] = this.state.lastStatus.next1;
        updates[`king/kingNext2`] = this.state.lastStatus.next2;
        updates[`king/kingNext3`] = this.state.lastStatus.next3;
        updates[`king/kingNext4`] = this.state.lastStatus.next4;
        updates[`king/kingScore1`] = this.state.lastStatus.score1;
        updates[`king/kingScore2`] = this.state.lastStatus.score2;
        updates[`king/kingScore3`] = this.state.lastStatus.score3;
        updates[`king/kingScore4`] = this.state.lastStatus.score4;
        updates[`king/kingScore5`] = this.state.lastStatus.score5;
        updates[`king/kingScore6`] = this.state.lastStatus.score6;
        Firebase.database().ref().update(updates);
    }


    setTimer() {
        const currentDate = new Date();
        const futureDate = currentDate.getTime() + this.props.minutesInGame * 60000;
        const updates = {};
        updates[`king/kingScore1`] = 0;
        updates[`king/kingScore2`] = 0;
        updates[`king/kingScore3`] = 0;
        updates[`king/kingScore4`] = 0;
        updates[`king/kingScore5`] = 0;
        updates[`king/kingScore6`] = 0;
        updates[`king/kingKing`] = 'TEAM-1';
        updates[`king/kingChallenger`] = 'TEAM-2';
        updates[`king/kingNext1`] = 'TEAM-3';
        if(this.state.teamCount < 4) {
            updates[`king/kingTeamName4`] = '';
            updates[`king/kingNext2`] = '';
        } else {
            updates[`king/kingNext2`] = 'TEAM-4';
        }
        if(this.state.teamCount < 5) {
            updates[`king/kingTeamName5`] = '';
            updates[`king/kingNext3`] = '';
        } else {
            updates[`king/kingNext3`] = 'TEAM-5';
        }
        if(this.state.teamCount < 6) {
            updates[`king/kingTeamName6`] = '';
            updates[`king/kingNext4`] = '';
        } else {
            updates[`king/kingNext4`] = 'TEAM-6';
        }

        updates[`king/kingEndOfTime`] = futureDate;
        Firebase.database().ref().update(updates);

    }

    updateMinutesInGame() {
        let mins = Number( this.state.minutesInGame);
        if(isNaN(mins )) {
            mins = 15;
        }
        const updates = {};
        updates[`king/kingMinutesInGame`] = mins;
        Firebase.database().ref().update(updates);
    }

    updateExtraMinutesInGame() {
        let extraMins = Number( this.state.extraMinutes);
        if(isNaN(extraMins )) {
            this.setState({ extraMinutes: 1 });
        } else {

            if (this.state.endOfTime) {
                let endTime = this.state.endOfTime;
                endTime += extraMins * 60000;
                const updates = {};
                updates[`king/kingEndOfTime`] = endTime;
                Firebase.database().ref().update(updates);
            }
        }
    }

    updateShowHideScoreboard() {
        let showScoreboard = Number( this.state.hideScoreboard);
        if(isNaN(showScoreboard )) {
            showScoreboard = 0;
        }
        if(showScoreboard === 1) {
            showScoreboard = 0;
        } else {
            showScoreboard = 1;
        }

        const updates = {};
        updates[`king/kingHideScoreboard`] = showScoreboard;
        Firebase.database().ref().update(updates);
    }




    updateScore(scoreId, score) {
        this.saveCurrentState();
        const updates = {};


         if (scoreId === "KING") {
             switch (this.state.king) {
                 case "TEAM-1": updates[`king/kingScore1`] = Firebase.database.ServerValue.increment(score); break;
                 case "TEAM-2": updates[`king/kingScore2`] = Firebase.database.ServerValue.increment(score); break;
                 case "TEAM-3": updates[`king/kingScore3`] = Firebase.database.ServerValue.increment(score); break;
                 case "TEAM-4": updates[`king/kingScore4`] = Firebase.database.ServerValue.increment(score); break;
                 case "TEAM-5": updates[`king/kingScore5`] = Firebase.database.ServerValue.increment(score); break;
                 case "TEAM-6": updates[`king/kingScore6`] = Firebase.database.ServerValue.increment(score); break;
                 default: break;
             }
        }

         if( (scoreId === "KING") ||  (scoreId === "CHALLENGER-FAIL") ) {
             updates[`king/kingChallenger`] = this.state.next1;

             if(this.state.teamCount === 3) {
                 updates[`king/kingNext1`] = this.state.challenger;
             }
             if(this.state.teamCount === 4) {
                 updates[`king/kingNext1`] = this.state.next2;
                 updates[`king/kingNext2`] = this.state.challenger;
             }
             if(this.state.teamCount === 5) {
                 updates[`king/kingNext1`] = this.state.next2;
                 updates[`king/kingNext2`] = this.state.next3;
                 updates[`king/kingNext3`] = this.state.challenger;
             }
             if(this.state.teamCount === 6) {
                 updates[`king/kingNext1`] = this.state.next2;
                 updates[`king/kingNext2`] = this.state.next3;
                 updates[`king/kingNext3`] = this.state.next4;
                 updates[`king/kingNext4`] = this.state.challenger;
             }
         }


        if (scoreId === "CHALLENGER") {

            updates[`king/kingKing`] = this.state.challenger;
            updates[`king/kingChallenger`] = this.state.next1;

             if(this.state.teamCount === 3) {
                 updates[`king/kingNext1`] = this.state.king;
             }
             if(this.state.teamCount === 4) {
                 updates[`king/kingNext1`] = this.state.next2;
                 updates[`king/kingNext2`] = this.state.king;
             }
             if(this.state.teamCount === 5) {
                 updates[`king/kingNext1`] = this.state.next2;
                 updates[`king/kingNext2`] = this.state.next3;
                 updates[`king/kingNext3`] = this.state.king;
             }
            if(this.state.teamCount === 6) {
                updates[`king/kingNext1`] = this.state.next2;
                updates[`king/kingNext2`] = this.state.next3;
                updates[`king/kingNext3`] = this.state.next4;
                updates[`king/kingNext4`] = this.state.king;
            }

        }
        Firebase.database().ref().update(updates);

    }



    render() {


        return (
            <React.Fragment>
                <div className="score-configure">

                    <div className="btn-king btn-king-scores" onClick={() =>  this.updateScore("KING", 1)} >
                        Kóngur vinnur
                    </div>
                    <div className="btn-king btn-challenger-scores" onClick={() => this.updateScore("CHALLENGER", 1)} >
                        Áskorandi vinnur
                    </div>

                    <div className="btn-king  btn-challenger-fail" onClick={() => this.updateScore("CHALLENGER-FAIL", 0)} >
                        Mistök í uppgjöf
                    </div>
                    <div className="btn-reset btn-reset-king" onClick={() => this.setTimer()} >
                        Byrja leik
                    </div>

                    <div className="input-set-minutes">
                    <div className="input-group">
                        <input type="text"
                               data-id="minutesinGame"
                               value={this.state.minutesInGame}
                               onChange={e => {
                                   this.setState({ minutesInGame: e.target.value });
                                   this.value = this.state.minutesInGame;
                               }}
                        />
                        <label>
                            Fjöldi mínútna í leik:
                        </label>
                        <div className="req-mark">!</div>
                    </div>
                    <button onClick={() => this.updateMinutesInGame()} className="btn btn-link">
                        Uppfæra fjölda mínútna
                    </button>
                        <br /><br />
                        <button onClick={() => this.updateShowHideScoreboard()} className="btn btn-link">
                            Sýna/fela stigatöflu
                        </button>
                        <br /><br />
                        <div className="input-group">
                            <input type="text"
                                   data-id="extraminutes"
                                   value={this.state.extraMinutes}
                                   onChange={e => {
                                       this.setState({ extraMinutes: e.target.value });
                                       this.value = this.state.extraMinutes;
                                   }}
                            />
                            <label>
                                Bæta við mínútum við leik:
                            </label>
                            <div className="req-mark">!</div>
                        </div>
                        <button onClick={() => this.updateExtraMinutesInGame()} className="btn btn-link">
                            Bæta við mínútum
                        </button>
                        <br /><br />
                        <button onClick={() => this.undoLastScore()} className="btn btn-link">
                            Undo last score
                        </button>

                    </div>


                </div>

            </React.Fragment>
        );

    }
}


export default KeypadKing;
