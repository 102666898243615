import React from 'react';
import Firebase from "firebase";
import config from "../config.js";


class settingsKing extends React.Component {

    constructor(props) {
        super(props);
        if (Firebase.apps.length === 0) {
            Firebase.initializeApp(config);
        }
        this.state = {
            team1: props.teamName1,
            team2: props.teamName2,
            team3: props.teamName3,
            team4: props.teamName4,
            team5: props.teamName5,
            team6: props.teamName6,
            score1: props.score1,
            score2: props.score2,
            score3: props.score3,
            score4: props.score4,
            score5: props.score5,
            score6: props.score6,
            teamCount: props.teamCount,
            endOfTime: props.endOfTime,
            order1: 1,
            order2: 2,
            order3: 3,
            order4: 4,
            order5: 5,
            order6: 6
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.teamName1 !== prevProps.teamName1) {
            this.setState({team1: this.props.teamName1});
        }
        if (this.props.teamName2 !== prevProps.teamName2) {
            this.setState({team2: this.props.teamName2});
        }
        if (this.props.teamName3 !== prevProps.teamName3) {
            this.setState({team3: this.props.teamName3});
        }
        if (this.props.teamName4 !== prevProps.teamName4) {
            this.setState({team4: this.props.teamName4});
        }
        if (this.props.teamName5 !== prevProps.teamName5) {
            this.setState({team5: this.props.teamName5});
        }
        if (this.props.teamName6 !== prevProps.teamName6) {
            this.setState({team6: this.props.teamName6});
        }
        if (this.props.score1 !== prevProps.score1) {
            this.setState({score1: this.props.score1});
        }
        if (this.props.score2 !== prevProps.score2) {
            this.setState({score2: this.props.score2});
        }
        if (this.props.score3 !== prevProps.score3) {
            this.setState({score3: this.props.score3});
        }
        if (this.props.score4 !== prevProps.score4) {
            this.setState({score4: this.props.score4});
        }
        if (this.props.score5 !== prevProps.score5) {
            this.setState({score5: this.props.score5});
        }
        if (this.props.score6 !== prevProps.score6) {
            this.setState({score6: this.props.score6});
        }
        if (this.props.teamCount !== prevProps.teamCount) {
            this.setState({teamCount: this.props.teamCount});
        }
        if (this.props.endOfTime !== prevProps.endOfTime) {
            this.setState({endOfTime: this.props.endOfTime});
        }
        if (this.props.minutesInGame !== prevProps.minutesInGame) {
            this.setState({minutesInGame: this.props.minutesInGame});
        }
    }

    updateTeamScores() {
        const updates = {};
        updates[`king/kingScore1`] = this.state.score1;
        updates[`king/kingScore2`] = this.state.score2;
        updates[`king/kingScore3`] = this.state.score3;
        updates[`king/kingScore4`] = this.state.score4;
        updates[`king/kingScore5`] = this.state.score5;
        updates[`king/kingScore6`] = this.state.score6;
        Firebase.database().ref().update(updates);
    }

    updateTeamCount() {
        let teamCount =  Number(this.state.teamCount);
        if(isNaN(teamCount )) {
            teamCount = 6;
        }
        if(teamCount <= 2) {
            teamCount = 3;
            this.setState({teamCount: teamCount});
        }
        if(teamCount > 6) {
            teamCount = 6;
            this.setState({teamCount: teamCount});
        }


        const updates = {};
        updates[`king/kingTeamCount`] =teamCount;
        Firebase.database().ref().update(updates);
    }

    updateTeamOrder() {

        const teamCount = this.state.teamCount;

        let teamListArray = [{name: this.state.team1, score: this.state.score1, order: this.state.order1}, {name: this.state.team2, score: this.state.score2, order: this.state.order2}, {name: this.state.team3, score: this.state.score3, order: this.state.order3}];

        if(teamCount > 3) {
            teamListArray.push({name: this.state.team4, score: this.state.score4, order: this.state.order4});
        };
        if(teamCount > 4) {
            teamListArray.push({name: this.state.team5, score: this.state.score5, order: this.state.order5});
        };
        if(teamCount > 5) {
            teamListArray.push({name: this.state.team6, score: this.state.score6, order: this.state.order6});
        };


        let sortable = teamListArray.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

        this.setState ({
            team1:  sortable[0].name,
            team2:  sortable[1].name,
            team3:  sortable[2].name,
            team4:  (teamCount > 3 ? sortable[3].name : ''),
            team5:  (teamCount > 4 ? sortable[4].name : ''),
            team6:  (teamCount > 5 ? sortable[5].name : ''),
            score1:  sortable[0].score,
            score2:  sortable[1].score,
            score3:  sortable[2].score,
            score4:  (teamCount > 3 ? sortable[3].score : ''),
            score5:  (teamCount > 4 ? sortable[4].score : ''),
            score6:  (teamCount > 5 ? sortable[5].score : ''),
            order1:  sortable[0].order,
            order2:  sortable[1].order,
            order3:  sortable[2].order,
            order4:  (teamCount > 3 ? sortable[3].order : ''),
            order5:  (teamCount > 4 ? sortable[4].order : ''),
            order6:  (teamCount > 5 ? sortable[5].order : ''),
        });



        const updates = {};
        updates[`king/kingTeamName1`] = sortable[0].name;
        updates[`king/kingScore1`] = sortable[0].score;
        updates[`king/kingTeamName2`] = sortable[1].name;
        updates[`king/kingScore2`] = sortable[1].score;
        updates[`king/kingTeamName3`] = sortable[2].name;
        updates[`king/kingScore3`] = sortable[2].score;
        if(teamCount > 3) {
            updates[`king/kingTeamName4`] = sortable[3].name;
            updates[`king/kingScore4`] = sortable[3].score;
        }
        if(teamCount > 4) {
            updates[`king/kingTeamName5`] = sortable[4].name;
            updates[`king/kingScore5`] = sortable[4].score;
        }
        if(teamCount > 5) {
            updates[`king/kingTeamName6`] = sortable[5].name;
            updates[`king/kingScore6`] = sortable[5].score;
        }

        updates[`king/kingKing`] =  'TEAM-1';
        updates[`king/kingChallenger`] =  'TEAM-2';
        updates[`king/kingNext1`] =  'TEAM-3';
        updates[`king/kingNext2`] =  'TEAM-4';
        updates[`king/kingNext3`] =  'TEAM-5';
        updates[`king/kingNext4`] =  'TEAM-6';

        Firebase.database().ref().update(updates);
    }

    updateTeamNames() {
        const updates = {};
        updates[`king/kingTeamName1`] = this.state.team1;
        updates[`king/kingTeamName2`] = this.state.team2;
        updates[`king/kingTeamName3`] = this.state.team3;
        updates[`king/kingTeamName4`] = this.state.team4;
        updates[`king/kingTeamName5`] = this.state.team5;
        updates[`king/kingTeamName6`] = this.state.team6;
        Firebase.database().ref().update(updates);
    }

    render() {

        return (
            <React.Fragment>
                <div className="menu">
                    <div className="brand">
                        <img src="/logo.svg" alt="Sandkastalinn"/>
                    </div>
                    <div className="input-group">
                        <input type="text"
                               data-id="score5"
                               value={this.state.teamCount}
                               onChange={e => {
                                   this.setState({ teamCount: e.target.value });
                                   this.value = this.state.teamCount;
                               }}
                        />
                        <label>
                            Fjöldi liða í keppni:
                        </label>
                        <div className="req-mark">!</div>
                    </div>
                    <button onClick={() => this.updateTeamCount()} className="btn btn-link">
                        Uppfæra fjölda liða
                    </button>
                 <div>
                 <div className="flex-columns">
                    <div className="flex-column big">
                    <div className="input-fields">
                        <h2>Nöfn á liðum</h2>
                        <div className="input-group">
                            <input type="text"
                                   data-id="team1"
                                   name="team1"
                                   value={this.state.team1}
                                   onChange={e => {
                                       this.setState({ team1: e.target.value });
                                       this.value = this.state.team1;
                                   }}
                            />

                            <label htmlFor="team1">
                                Lið #1:
                            </label>

                            <div className="req-mark">!</div>
                        </div>
                    </div>
                    <div>


                        <div className="input-group">
                            <input type="text"
                                   data-id="team2"
                                   value={this.state.team2}
                                   onChange={e => {
                                       this.setState({ team2: e.target.value });
                                       this.value = this.state.team2;
                                   }}
                            />
                            <label>
                                Lið #2:
                            </label>
                            <div className="req-mark">!</div>
                        </div>
                        <div className="input-group">
                            <input type="text"
                                   data-id="team3"
                                   value={this.state.team3}
                                   onChange={e => {
                                       this.setState({ team3: e.target.value });
                                       this.value = this.state.team3;
                                   }}
                            />
                            <label>
                                Lið #3:
                            </label>
                            <div className="req-mark">!</div>
                        </div>
                        {this.props.teamCount > 3 &&
                        <div className="input-group">
                            <input type="text"
                                   data-id="team4"
                                   value={this.state.team4}
                                   onChange={e => {
                                       this.setState({team4: e.target.value});
                                       this.value = this.state.team4;
                                   }}
                            />
                            <label>
                                Lið #4:
                            </label>
                            <div className="req-mark">!</div>
                        </div>
                        }
                        {this.props.teamCount > 4 &&
                        <div className="input-group">
                            <input type="text"
                                   data-id="team5"
                                   value={this.state.team5}
                                   onChange={e => {
                                       this.setState({team5: e.target.value});
                                       this.value = this.state.team5;
                                   }}
                            />
                            <label>
                                Lið #5:
                            </label>
                            <div className="req-mark">!</div>
                        </div>

                        }
                        {this.props.teamCount > 5 &&
                        <div className="input-group">
                            <input type="text"
                                   data-id="team6"
                                   value={this.state.team6}
                                   onChange={e => {
                                       this.setState({team6: e.target.value});
                                       this.value = this.state.team6;
                                   }}
                            />
                            <label>
                                Lið #6:
                            </label>
                            <div className="req-mark">!</div>
                        </div>

                        }

                        <button
                            onClick={() => this.updateTeamNames()}
                            className="btn btn-link"
                        >
                            Uppfæra nöfn
                        </button>

                    </div>

                    </div>
                    <div className="flex-column small">

                    <div className="input-fields">
                        <h2>Stig hjá liðum</h2>
                        <div className="input-group">
                            <input type="text"
                                   data-id="score1"
                                   name="score1"
                                   value={this.state.score1}
                                   onChange={e => {
                                       this.setState({ score1: e.target.value });
                                       this.value = this.state.score1;
                                   }}
                            />

                            <label htmlFor="team1">
                                Lið #1:
                            </label>
                        </div>
                    </div>
                    <div>


                        <div className="input-group">
                            <input type="text"
                                   data-id="score2"
                                   value={this.state.score2}
                                   onChange={e => {
                                       this.setState({ score2: e.target.value });
                                       this.value = this.state.score2;
                                   }}
                            />
                            <label>
                                Lið #2:
                            </label>
                        </div>
                        <div className="input-group">
                            <input type="text"
                                   data-id="score3"
                                   value={this.state.score3}
                                   onChange={e => {
                                       this.setState({ score3: e.target.value });
                                       this.value = this.state.score3;
                                   }}
                            />
                            <label>
                                Lið #3:
                            </label>
                        </div>
                        {this.props.teamCount > 3 &&
                        <div className="input-group">
                            <input type="text"
                                   data-id="score4"
                                   value={this.state.score4}
                                   onChange={e => {
                                       this.setState({score4: e.target.value});
                                       this.value = this.state.score4;
                                   }}
                            />
                            <label>
                                Lið #4:
                            </label>
                        </div>
                        }
                        {this.props.teamCount > 4 &&
                        <div className="input-group">
                            <input type="text"
                                   data-id="score5"
                                   value={this.state.score5}
                                   onChange={e => {
                                       this.setState({score5: e.target.value});
                                       this.value = this.state.score5;
                                   }}
                            />
                            <label>
                                Lið #5:
                            </label>
                        </div>
                        }
                        {this.props.teamCount > 5 &&
                        <div className="input-group">
                            <input type="text"
                                   data-id="score6"
                                   value={this.state.score6}
                                   onChange={e => {
                                       this.setState({score6: e.target.value});
                                       this.value = this.state.score6;
                                   }}
                            />
                            <label>
                                Lið #6:
                            </label>
                        </div>
                        }

                        <button
                            onClick={() => this.updateTeamScores()}
                            className="btn btn-link"
                        >
                            Uppfæra stig
                        </button>


                    </div>                    </div>
                     <div className="flex-column small">

                         <div className="input-fields">
                             <h2>Röð hjá liðum</h2>
                             <div className="input-group">
                                 <input type="text"
                                        data-id="order1"
                                        name="order1"
                                        value={this.state.order1}
                                        onChange={e => {
                                            this.setState({ order1: e.target.value });
                                            this.value = this.state.order1;
                                        }}
                                 />

                                 <label htmlFor="team1">
                                     Lið #1:
                                 </label>
                             </div>
                         </div>
                         <div>


                             <div className="input-group">
                                 <input type="text"
                                        data-id="order2"
                                        value={this.state.order2}
                                        onChange={e => {
                                            this.setState({ order2: e.target.value });
                                            this.value = this.state.order2;
                                        }}
                                 />
                                 <label>
                                     Lið #2:
                                 </label>
                             </div>
                             <div className="input-group">
                                 <input type="text"
                                        data-id="order3"
                                        value={this.state.order3}
                                        onChange={e => {
                                            this.setState({ order3: e.target.value });
                                            this.value = this.state.order3;
                                        }}
                                 />
                                 <label>
                                     Lið #3:
                                 </label>
                             </div>
                             {this.props.teamCount > 3 &&
                             <div className="input-group">
                                 <input type="text"
                                        data-id="order4"
                                        value={this.state.order4}
                                        onChange={e => {
                                            this.setState({order4: e.target.value});
                                            this.value = this.state.order4;
                                        }}
                                 />
                                 <label>
                                     Lið #4:
                                 </label>
                             </div>
                             }
                             {this.props.teamCount > 4 &&
                             <div className="input-group">
                                 <input type="text"
                                        data-id="order5"
                                        value={this.state.order5}
                                        onChange={e => {
                                            this.setState({order5: e.target.value});
                                            this.value = this.state.order5;
                                        }}
                                 />
                                 <label>
                                     Lið #5:
                                 </label>
                             </div>
                             }
                             {this.props.teamCount > 5 &&
                             <div className="input-group">
                                 <input type="text"
                                        data-id="order6"
                                        value={this.state.order6}
                                        onChange={e => {
                                            this.setState({order6: e.target.value});
                                            this.value = this.state.order6;
                                        }}
                                 />
                                 <label>
                                     Lið #6:
                                 </label>
                             </div>
                             }


                             <button
                                 onClick={() => this.updateTeamOrder()}
                                 className="btn btn-link"
                             >
                                 Uppfæra röð
                             </button>


                         </div>                    </div>
                </div>



                </div>




                </div>
            </React.Fragment>
        );

    }
}


export default settingsKing;
